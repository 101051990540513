<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/mckesson_plugin.svg"
          alt="mckesson logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} McKesson
      </template>
      <template #subheader>
        Please configure the following in order to install the McKesson plugin.
      </template>
    </PageHeader>

    <div class="section-header">
      Configuration
    </div>
    <div>
      Please map the following McKesson fields to a custom field.
    </div>
    <validation-observer
      ref="inputRef"
    >
      <v-simple-table class="field-table mt-3 mb-5">
        <thead>
          <tr>
            <th>File Header</th>
            <th>Custom Field</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="mappedField in mappedFields"
            :key="mappedField"
          >
            <td>
              <span
                class="field__subheader--required"
              >
                *
              </span>
              {{ mappedField }}
            </td>
            <td>
              <CustomDropdown
                v-model="fieldMapping[mappedField]"
                :items="customFieldOptions"
                autocomplete
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div>
        <CustomTextInput
          v-model="username"
          header="Username"
          required
        />
      </div>
      <div>
        <CustomTextInput
          v-model="password"
          header="Password"
          type="password"
          required
        />
      </div>
      <div>
        <CustomDropdown
          v-model="segmentsToEnroll"
          :items="segments"
          header="Segments to Enroll with McKesson"
          item-value="id"
          item-text="name"
          required
          multiple
          autocomplete
          hint="The contact must have a full location and must belong to one of these segments for the data to be sent to McKesson via API."
        />
      </div>
      <div>
        <CustomTextInput
          v-model="brandName"
          header="Brand Name"
          required
          hint="This value will be sent to McKesson as the brand_name value."
        />
      </div>
      <div>
        <CustomTextInput
          v-model="apiSource"
          header="API Source"
          required
          hint="This value will be sent to McKesson as the source value."
        />
      </div>
      <div>
        <CustomDropdown
          v-model="creativeId"
          :items="creatives"
          header="Direct Mail Creative"
          item-value="id"
          item-text="name"
          required
          autocomplete
          hint="The Vendor Creative ID for this creative will be sent to McKesson as the fulfillment_type value. Also it will be the creative tied to Direct Mail Actions saved by McKesson imports."
        />
      </div>
      <div>
        <CustomTextInput
          v-model="alertNotificationRecipients"
          header="Alert Notification Recipients"
          hint="This is a comma-separated list of email addresses that should be notified when contacts have failed to be sent to McKesson 3 times."
        />
      </div>
    </validation-observer>

    <hr class="mt-7">

    <div class="section-header">
      Automated Imports
    </div>
    <div class="mt-8">
      <v-btn
        class="custom-button custom-button--blue px-13"
        height="34px"
        depressed
        :block="$vuetify.breakpoint.xsOnly"
        @click="addImportConfiguration(null)"
      >
        Add Import Configuration
      </v-btn>
    </div>

    <div v-for="(form, index) in importConfigurations" :key="index" class="mt-4">
      <v-expansion-panels v-model="form.isShowing">
        <v-expansion-panel>
          <v-expansion-panel-header v-if="form.name">
            {{ form.name }}
          </v-expansion-panel-header>
          <v-expansion-panel-header v-else>
            Configuration #{{ index + 1 }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <AutomatedImportForm
              :form="form"
              @clone-configuration="cloneImportConfiguration(index)"
              @remove-configuration="removeImportConfiguration(index)"
              @update-value="(key, value) => updateImportValue(index, key, value)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-row class="mt-12">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          v-if="installed"
          class="custom-button custom-button--red-text px-8 mr-4"
          height="34px"
          depressed
          @click="deactivateDialog = true;"
        >
          Deactivate
        </v-btn>

        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="installPlugin(null, null, false)"
        >
          {{ installed ? "Configure" : "Install" }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="deleteImportDialog" max-width="360px" @click:outside="deleteImportId = null">
      <DeleteConfirmationDialog
        v-if="deleteImportDialog"
        header="Delete configuration"
        subheader="Are you sure you want to delete this import configuration? All unsaved changes will be removed."
        @dismiss="
          deleteImportDialog = false;
          deleteImportId = null;
        "
        @delete="deleteImportConfig"
      />
    </v-dialog>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import AutomatedImportForm from "@/views/Plugins/components/AutomatedImportForm";
import { v4 as uuidv4 } from "uuid";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";
import importExportMixin from "@/utils/import-export-mixin";

export default {
  name: "AddPluginMcKesson",
  metaInfo: {
    title: 'Install McKesson'
  },
  components: {
    AutomatedImportForm,
    PageHeader,
    CustomTextInput,
    CustomDropdown,
    DeleteConfirmationDialog,
    DeactivateConfirmDialog,
  },
  mixins: [importExportMixin],
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    segments: [],
    creatives: [],
    deleteImportDialog: null,
    deleteImportId: null,
    importConfigurations: [],
    fieldMapping: {},
    plugin: null,
    username: null,
    password: null,
    brandName: null,
    apiSource: null,
    creativeId: null,
    segmentsToEnroll: [],
    alertNotificationRecipients: null,
    mappedFields: [
      'McKesson Case ID',
      'McKesson OBCOMM ID',
      'McKesson Sent Date',
      'McKesson Send Attempts',
    ],
  }),
  computed: {
    customFieldOptions() {
      const customFields = this.$store.getters['user/customFields']?.contact;

      if (!customFields) {
        return [];
      }

      return [
        {
          label: 'Automatically create a custom field',
          value: null,
        },
        ...customFields.map((field) => {
          return {
            label: field.name,
            value: field.fieldName,
          };
        })
      ];
    },
  },
  async created() {
    await this.loadSegments();
    await this.loadCreatives();

    if (this.installed) {
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadSegments() {
      let segments = (await this.$rest.segment.get_collection({
        ignorePagination: true
      })).data

      this.segments = segments.items.map(segment => ({
        ...segment,
        name: segment.isArchived ? segment.name + " [Archived]" : segment.name
      }));
    },
    async loadCreatives() {
      let creatives = (await this.$rest.directmails.get_collection({
        ignorePagination: true
      })).data;

      this.creatives = creatives.items.map(creative => ({
        ...creative,
        name: creative.id + ' - ' + (creative.isArchived ? creative.name + " [Archived]" : creative.name)
      }));
    },
    async loadAccountPlugin() {
      const resp = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!resp.data) {
        return;
      }

      this.plugin = {...resp.data};

      let credentials = resp.data.pluginConfiguration?.credentials;
      if (!credentials || Array.isArray(credentials)) {
        credentials = {};
      } else {
        this.username = credentials?.auth?.username;
        this.password = credentials?.auth?.password;
      }

      this.fieldMapping = resp.data.pluginConfiguration?.fieldMapping ?? {};
      if (Array.isArray(this.fieldMapping)) {
        this.fieldMapping = {};
      }

      this.brandName = resp.data.pluginConfiguration?.brandName;
      this.apiSource = resp.data.pluginConfiguration?.apiSource;
      this.creativeId = resp.data.pluginConfiguration?.creativeId;
      this.segmentsToEnroll = resp.data.pluginConfiguration?.segmentsToEnroll;
      this.alertNotificationRecipients = resp.data.pluginConfiguration?.alertNotificationRecipients;

      const configMapFunc = config => {
        const configCredentials = credentials[config.key] ?? null;

        if(config?.frequency?.time) {
          const [hour, minute] = config.frequency.time.split(":");
          if (+hour >= 12) {
            config.frequency.ampm = "PM"
            config.frequency.hour = +hour > 12 ? +hour - 12 : 12;
          }
          else {
            config.frequency.ampm = "AM"
            config.frequency.hour = (+hour === 0) ? 12 : hour;
          }
          config.frequency.minute = minute;

          // Remove this config in case the frequency changes
          delete config.frequency.nextRunDate;
        }

        return {
          ...config,
          credentials: configCredentials,
        };
      };

      if (resp.data.pluginConfiguration?.importConfigurations) {
        const importConfigurations = resp.data.pluginConfiguration?.importConfigurations.map(configMapFunc);
        importConfigurations.forEach(config => {
          this.addImportConfiguration(config);
        });
      }
    },
    addImportConfiguration(existingConfig) {
      this.importConfigurations.push({
        connectionType: existingConfig?.connectionType || null,
        directory: existingConfig?.directory || '/',
        archiveDirectory: existingConfig?.archiveDirectory || '',
        fileExtension: existingConfig?.fileExtension || 'csv',
        credentials: {
          host: existingConfig?.credentials?.host || null,
          username: existingConfig?.credentials?.username || null,
          password: existingConfig?.credentials?.password || null,
          port: existingConfig?.credentials?.port || null,
          privateKey: existingConfig?.credentials?.privateKey || null,
          aws_region: existingConfig?.credentials?.aws_region || null,
          aws_access_id: existingConfig?.credentials?.aws_access_id || null,
          aws_secret_key: existingConfig?.credentials?.aws_secret_key || null,
          aws_bucket: existingConfig?.credentials?.aws_bucket || null,
        },
        frequency: existingConfig?.frequency || {
          type: null,
          ampm: (new Date()).getHours() >= 12 ? 'PM' : 'AM',
          hour: (new Date()).getHours(),
          minute: '00',
          timezone: 'America/New_York',
          date: [],
        },
        importType: 'mckesson_confirmation',
        importOptions: existingConfig?.importOptions ? {...existingConfig?.importOptions} : { choice: null },
        name: existingConfig?.name || '',
        notificationRecipients: existingConfig?.notificationRecipients || '',
        key: existingConfig?.key || uuidv4(),
        isShowing: false,
        history: existingConfig?.history ? [...existingConfig?.history] : [],
        isExisting: !!existingConfig?.key,
      });
    },
    cloneImportConfiguration(index) {
      const existingConfig = this.importConfigurations[index] ?? null;
      this.addImportConfiguration({
        ...existingConfig,
        name: 'Clone of ' + existingConfig?.name || '',
        key: null,
        history: null,
      });
    },
    removeImportConfiguration(index) {
      this.deleteImportDialog = true;
      this.deleteImportId = index;
    },
    async deleteImportConfig() {
      if (this.installed) {
        const modifiedImportConfig = this.importConfigurations.slice();
        modifiedImportConfig.splice(this.deleteImportId, 1);
        await this.installPlugin(modifiedImportConfig, true);

        this.importConfigurations.splice(this.deleteImportId, 1);

        this.$store.commit('snackbar/showMessage', {
          content: 'Import Configuration Deleted',
          color: 'success',
        });
      } else {
        this.importConfigurations.splice(this.deleteImportId, 1);
      }

      this.deleteImportDialog = null;
      this.deleteImportId = null;
    },
    updateImportValue(index, key, value) {
      const configuration = this.importConfigurations[index];

      if (key.includes('.')) {
        const [parentKey, childKey] = key.split('.');

        configuration[parentKey][childKey] = value;
        const update = {};
        update[childKey] = value;
        this.$set(configuration, parentKey, {
          ...configuration[parentKey],
          ...update
        });
      } else {
        configuration[key] = value;
        this.$set(configuration, key, value);
      }
    },
    getFormattedPluginConfiguration(expectedImportConfig) {
      const pluginConfiguration = {
        importConfigurations: [],
        fieldMapping: this.fieldMapping,
        credentials: {
          auth: {
            username: this.username,
            password: this.password,
          }
        },
        brandName: this.brandName,
        apiSource: this.apiSource,
        creativeId: this.creativeId,
        segmentsToEnroll: this.segmentsToEnroll,
        alertNotificationRecipients: this.alertNotificationRecipients,
      };

      for (const config of (expectedImportConfig || this.importConfigurations)) {
        const result = this.formatConfig(config);

        pluginConfiguration.importConfigurations.push(result.configuration);
        pluginConfiguration.credentials[result.configuration.key] = result.credentials;
      }

      return pluginConfiguration;
    },
    async installPlugin(expectedImportConfig, returnResponse) {
      const pluginConfiguration = this.getFormattedPluginConfiguration(expectedImportConfig);

      let response = null;
      if (this.installed) {
        response = await this.$rest.plugins
          .put_resource(this.id, {
            isActive: true,
            pluginConfiguration,
          })
          .catch(error => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to configure plugin',
              color: 'error',
            });
          });
      } else {
        response = await this.$rest.plugins
          .post_resource({
            plugin: +this.id,
            isActive: true,
            pluginConfiguration,
          })
            .then((resp) => {
              // Reload the selected account in case custom fields got created
              const selectedAccount = this.$store.getters["user/account"];
              this.$store.dispatch(
                  'user/selectAccount',
                  selectedAccount.accountId,
                  selectedAccount.logo
              );

              return resp;
            })
          .catch((error) => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to install plugin',
              color: 'error',
            });
          });
      }

      if (returnResponse) {
        return response;
      }
      if (!response?.data) {
        return;
      }

      this.$store.commit('snackbar/showMessage', {
        content: this.installed
          ? 'Plugin configured successfully'
          : 'Plugin installed successfully',
        color: 'success',
      });

      await this.$router.push({ name: 'Plugins' });
    },
  },
};
</script>

<style scoped>
.section-header {
  margin-top: 30px;
  font-size: 1.25rem;
  font-weight: bold;
}
.field-table {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.field__subheader--required {
  color: #e74c3c;
}
</style>
